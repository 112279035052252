
import { loadSectionTranslations } from '../../i18n-setup'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import { createNamespacedHelpers } from 'vuex'
import SghPaymentInfoPopup from '../payment-info/sgh-payment-info-popup.vue'
import { ACTION_TOGGLE_POPUP_ID } from '../../store/actions/ui'
const { mapActions: mapActionsUi } = createNamespacedHelpers('ui')
const { mapGetters: mapGettersUi } = createNamespacedHelpers('ui')
export default {
  name: 'sgh-pay-over-time',
  components: {
    SghPaymentInfoPopup,
  },
  props: {
    pageType: {
      type: String,
      default: '',
    },
    loadedProducts: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
    }
  },

  computed: {
    ...mapGettersUi(['popups']),
    isVisible() {
      return (
        this.afterpayIsEnabled ||
        this.klarnaIsEnabled ||
        this.paypalIsEnabled ||
        this.isPaymentInstallment
      )
    },
    klarnaIsEnabled() {
      return window.paymentInstallmentList?.klarna?.enabled
    },
    paypalIsEnabled() {
      return window.paymentInstallmentList?.paypal?.enabled
    },
    afterpayIsEnabled() {
      return window.paymentInstallmentList?.afterpay?.enabled
    },
    getPaymentMethodsLabel() {
      const labels = []
      const wrapWithUnderline = label => (this.pageType === 'pdp' ? `<u>${label}</u>` : label)

      if (this.afterpayIsEnabled) {
        labels.push(`<strong class="${this.fontWeightClass}" tabindex="0">${wrapWithUnderline(this.$t('afterpay_label'))}</strong>`)
      }
      if (this.paypalIsEnabled) {
        labels.push(`<strong class="${this.fontWeightClass}" tabindex="0">${wrapWithUnderline(this.$t('paypal_label'))}</strong>`)
      }
      if (this.klarnaIsEnabled) {
        const klarnaLabel = wrapWithUnderline(this.$t('klarna_label'))
        labels.push(
          this.pageType === 'pdp'
            ? `<strong class="font-medium" tabindex="0">${klarnaLabel}.</strong>`
            : `<strong class="${this.fontWeightClass}">${klarnaLabel}</strong>`
        )
      }

      if (labels.length === 1) {
        return labels[0]
      } else if (labels.length === 2) {
        return labels.join(` ${this.$t('pay_over_time_or')} `)
      } else if (labels.length >= 3) {
        const lastLabel = labels.pop()
        const combinedLabels = `${labels.join(', ')} ${this.$t('pay_over_time_or')} ${lastLabel}`
        return combinedLabels
      }

      return ''
    },

    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    isBr() {
      return getCurrentCountry() === 'br'
    },
    isPaymentInstallment() {
      return window.wcs_feature_paymentInstallment?.isActive
    },
    fontWeightClass() {
      const pagesArr = ['cart', 'shipping', 'billing']
      return pagesArr.includes(window.pageType) ? 'font-bold' : 'font-medium'
    }
  },

  mounted() {
    this.$root.$on('sghPopupTypeClosed', popupId => {
      popupId === 'payOverTimePopup'
      this.closePayOverTimePopup()
    })
    loadSectionTranslations('minicart')
  },

  methods: {
    ...mapActionsUi({
      togglePopup: ACTION_TOGGLE_POPUP_ID,
    }),
    openPayOverTimePopup() {
      this.togglePopup({ popupId: 'payOverTimePopup' })
    },
    closePayOverTimePopup() {
      this.visible = false
    },
    getInstallmentsInfo() {
      const priceListKey = 'SGH_BR ProductsPriceList',
        paymentInstallmentText = this.loadedProducts.prices?.[priceListKey]?.installmentText
      return paymentInstallmentText
    },
  },
}
