import { Commit } from 'vuex';

import {
  UPDATE_MINICART_STATUS,
  UPDATE_POPUP_PROP,
  UPDATE_SHOW_LOADER, UPDATE_TOAST_STATUS, UPDATE_TOGGLE_PANEL_ID,
  UPDATE_TOGGLE_POPUP, UPDATE_TOGGLE_POPUP_ID,
} from '../mutations/ui'
import { ACTION_TOGGLE_POPUP } from './pdp'

export const ACTION_SET_OPEN_POPUP = 'ACTION_SET_OPEN_POPUP'
export const ACTION_SET_SHOW_LOADER = 'ACTION_SET_SHOW_LOADER'
export const ACTION_TOGGLE_POPUP_ID = 'ACTION_TOGGLE_POPUP_ID'
export const ACTION_TOGGLE_PANEL_ID = 'ACTION_TOGGLE_PANEL_ID'
export const ACTION_UPDATE_POPUP_PROP = 'ACTION_UPDATE_POPUP_PROP'
export const ACTION_UPDATE_TOAST_STATUS = 'ACTION_UPDATE_TOAST_STATUS'
export const ACTION_UPDATE_MINICART_STATUS = 'ACTION_UPDATE_MINICART_STATUS'
export const actions = {
  [ACTION_SET_OPEN_POPUP]: async function({ commit }: { commit: Commit }, payload: boolean) {
    commit(UPDATE_TOGGLE_POPUP, payload)
  },
  [ACTION_SET_SHOW_LOADER]: async function({ commit }: { commit: Commit }, payload: boolean) {
    commit(UPDATE_SHOW_LOADER, payload)
  },
  [ACTION_TOGGLE_POPUP_ID]: async function({ commit }: { commit: Commit },payload:{
    popupId:string
    open?: boolean
  }) {
    commit(UPDATE_TOGGLE_POPUP_ID,payload)
  },
  [ACTION_TOGGLE_PANEL_ID]: async function({ commit }: { commit: Commit },payload:{
    panelId:string
    open?: boolean
    }) {
    commit(UPDATE_TOGGLE_PANEL_ID,payload)
  },
  [ACTION_UPDATE_POPUP_PROP]: async function({ commit }: { commit: Commit },payload:{
    popupId:string,
    props:Record<string, string | boolean>
  }) {
    commit(UPDATE_POPUP_PROP,payload)
  },
  [ACTION_UPDATE_TOAST_STATUS]: async function({ commit }: { commit: Commit },payload:{
    toastId:string,
    open?: boolean
  }) {
    commit(UPDATE_TOAST_STATUS,payload)
  },
  [ACTION_UPDATE_MINICART_STATUS]: async function({ commit }: { commit: Commit },payload?:{
    open?: boolean
  }) {
    commit(UPDATE_MINICART_STATUS,payload)
  },
}
