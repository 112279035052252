import VueCookies from 'vue-cookies'
export const UPDATE_LOGON_MYACCOUNT = 'UPDATE_LOGON_MYACCOUNT'
export const UPDATE_CRYPTO_DATA_MYACCOUNT = 'UPDATE_CRYPTO_DATA_MYACCOUNT'
export const UPDATE_PASSWORD_REQUIREMENTS = 'UPDATE_PASSWORD_REQUIREMENTS'
export const UPDATE_ERROR_LOGON_MYACCOUNT = 'UPDATE_ERROR_LOGON_MYACCOUNT'
export const UPDATE_LOGON_RESET_ERROR = 'UPDATE_LOGON_RESET_ERROR'
export const UPDATE_ERROR_PROFILE_DETAILS = 'UPDATE_ERROR_PROFILE_DETAILS'
export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS'
export const UPDATE_RESET_ERROR_PROFILE_DETAILS = 'UPDATE_RESET_ERROR_PROFILE_DETAILS'
export const UPDATE_PASSWORD_CHANGED = 'UPDATE_PASSWORD_CHANGED'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const UPDATE_SINGLE_ADDRESS = 'UPDATE_SINGLE_ADDRESS'
export const UPDATE_PROFILE_API_STATUS = 'UPDATE_PROFILE_API_STATUS'
export const UPDATE_CHANGE_PASSWORD_ERRROR = 'UPDATE_CHANGE_PASSWORD_ERRROR'
export const UPDATE_ORDER_RETURN_HISTORY = 'UPDATE_ORDER_HISTORY'
export const UPDATE_ERROR_MESSAGE_HISTORY = 'UPDATE_ERROR_MESSAGE_HISTORY'
export const UPDATE_ORDER_DETAIL_UPLOAD_PRESCRIPTION = 'UPDATE_ORDER_DETAIL_UPLOAD_PRESCRIPTION'
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB'
export const UPDATE_ERROR_MESSAGE_ORDER_DETAIL_UPLOAD_PRESCRIPTION =
  'UPDATE_ERROR_MESSAGE_ORDER_DETAIL_UPLOAD_PRESCRIPTION'
export const UPDATE_ADDRESS_ZIPCODE_AUTOCOMPLETE = 'UPDATE_ADDRESS_ZIPCODE_AUTOCOMPLETE'
export const UPDATE_ADDRESS_EMPTY_ZIPCODE_AUTOCOMPLETE = 'UPDATE_ADDRESS_EMPTY_ZIPCODE_AUTOCOMPLETE'
export const UPDATE_STATE_AND_ZIP_REGEX = 'UPDATE_STATE_AND_ZIP_REGEX'
export const UPDATE_ERROR_MESSAGE_RETURN_LABEL = 'UPDATE_ERROR_MESSAGE_RETURN_LABEL'
export const UPDATE_URL_ORDER_LABEL = 'UPDATE_URL_ORDER_LABEL'
export const UPDATE_LOADER_ORDER_RETURN_HISTORY = 'UPDATE_LOADER_ORDER_RETURN_HISTORY'
export const UPDATE_ERROR_DOWNLOAD_INVOICE_FILE = 'UPDATE_ERROR_DOWNLOAD_INVOICE_FILE'
export const UPDATE_INVOICE_FILE = 'UPDATE_INVOICE_FILE'
export const UPDATE_FAVOURITESTORES_LOADING = 'UPDATE_FAVOURITESTORES_LOADING'
export const UPDATE_APPOINTMENTSBOOKING_LOADING = 'UPDATE_APPOINTMENTSBOOKING_LOADING'
export const UPDATE_LOYALTYPRODUCTS_LOADING = 'UPDATE_LOYALTYPRODUCTS_LOADING'
export const UPDATE_LOYALTY_SELECTED_PRODUCT = 'UPDATE_LOYALTY_SELECTED_PRODUCT'
export const UPDATE_LOAD_LOYALTY_SCRIPT = 'UPDATE_LOAD_LOYALTY_SCRIPT'
export const UPDATE_LOYALTY_STATUS = 'UPDATE_LOYALTY_STATUS'
export const UPDATE_CART_STATE = 'UPDATE_CART_STATE'

const deleteCookie = cookie => {
  VueCookies.remove(cookie)
}

const setLoyaltyTrackingValues = objectPerson => {
  const loyaltyCardNumber = objectPerson?.loyaltyCardNumber || ''
  const loyaltyTier = objectPerson?.loyaltyTier || ''
  window.localStorage.setItem('loyaltyCardNumber', loyaltyCardNumber)
  window.localStorage.setItem('loyaltyTier', loyaltyTier)
}

export const mutations = {
  [UPDATE_LOGON_MYACCOUNT]: function(state, payload) {
    state.myAccountDetails.loggedIn = payload
    if (window.constants && window.constants.ajaxParams.userType.length != 0) {
      payload
        ? (window.constants.ajaxParams.userType = 'R')
        : ((window.constants.ajaxParams.userType = 'G'),
          deleteCookie(`SHA256Email`),
          deleteCookie(`string_md5`),
          (state.myAccountDetails.crypto.sha256 = ''),
          (state.myAccountDetails.crypto.string_md5 = ''))
    }
  },
  [UPDATE_CRYPTO_DATA_MYACCOUNT]: function(state, payload) {
    state.myAccountDetails.crypto = payload
  },

  [UPDATE_ERROR_LOGON_MYACCOUNT]: function(state, payload) {
    state.myAccountDetails.error = payload
  },
  [UPDATE_LOGON_RESET_ERROR]: function(state) {
    state.myAccountDetails.error = null
  },
  [UPDATE_PROFILE_DETAILS]: function(state, payload) {
    state.profileDetails.objectPerson = payload
    if (
      sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true' &&
      (state?.profileDetails?.objectPerson?.loyaltyCardNumber ||
        state?.profileDetails?.objectPerson?.loyaltyTier)
    ) {
      setLoyaltyTrackingValues(state.profileDetails.objectPerson)
    }
  },
  [UPDATE_ADDRESS]: function(state, payload) {
    state.profileDetails.objectPerson.address = payload
  },
  [UPDATE_ERROR_PROFILE_DETAILS]: function(state, payload) {
    state.profileDetails.error = payload
  },
  [UPDATE_RESET_ERROR_PROFILE_DETAILS]: function(state) {
    state.profileDetails.error = null
  },
  [UPDATE_PASSWORD_CHANGED]: function(state, payload) {
    state.recoveryDetails.passwordChanged = payload
  },
  [UPDATE_SINGLE_ADDRESS]: function(state, payload) {
    state.profileDetails.currentAddressPopup = payload
  },
  [UPDATE_PROFILE_API_STATUS]: function(state, payload) {
    state.profileApiBusy = payload
  },
  [UPDATE_CHANGE_PASSWORD_ERRROR]: function(state, payload) {
    state.recoveryDetails.passwordChangedError = payload
  },
  [UPDATE_ORDER_RETURN_HISTORY]: function(state, payload) {
    state.orders.orderReturnHistory = payload
    state.orders.orderReturnLabel = ''
  },
  [UPDATE_ERROR_MESSAGE_HISTORY]: function(state, payload) {
    state.orderDetails.error = payload.data['Error message'] ? payload.data['Error message'] : ''
    state.orderDetails.errorId = payload.status ? payload.status.toString() : ''
  },
  [UPDATE_ORDER_DETAIL_UPLOAD_PRESCRIPTION]: function(state, payload) {
    state.orders.prescriptionId = payload
    state.orders.prescriptionError = false
  },
  [UPDATE_ERROR_MESSAGE_ORDER_DETAIL_UPLOAD_PRESCRIPTION]: function(state, payload) {
    state.orders.prescriptionError = payload
  },
  [UPDATE_BREADCRUMB]: function(state, payload) {
    if (!Array.isArray(payload)) return (state.breadcrumb = [])
    state.breadcrumb = payload
  },
  [UPDATE_ADDRESS_ZIPCODE_AUTOCOMPLETE]: function(state, payload) {
    state.profileDetails.zipcodeAutocomlete = payload
    state.profileDetails.zipCodeValid = true
  },
  [UPDATE_ADDRESS_EMPTY_ZIPCODE_AUTOCOMPLETE]: function(state, payload = true) {
    state.profileDetails.zipcodeAutocomlete = []
    state.profileDetails.zipCodeValid = payload
  },
  [UPDATE_PASSWORD_REQUIREMENTS]: function(state, payload) {
    state.passwordRequirements = payload
  },
  [UPDATE_STATE_AND_ZIP_REGEX]: function(state, payload) {
    state.profileDetails.countryInfo = payload
  },
  [UPDATE_URL_ORDER_LABEL]: function(state, payload) {
    state.orders.errorReturnLabel = false
    state.orders.orderReturnLabel = payload
  },
  [UPDATE_ERROR_MESSAGE_RETURN_LABEL]: function(state, payload) {
    state.orders.errorReturnLabel = payload
  },
  [UPDATE_LOADER_ORDER_RETURN_HISTORY]: function(state, payload) {
    state.orders.isLoading = payload
  },
  [UPDATE_ERROR_DOWNLOAD_INVOICE_FILE]: function(state, payload) {
    state.orders.errorDownloadInoviceFile = payload
  },
  [UPDATE_INVOICE_FILE]: function(state, payload) {
    state.orders.invoiceFile = payload
  },

  [UPDATE_FAVOURITESTORES_LOADING]: function(state, payload) {
    const defaultStoreIndex = payload.findIndex(element => element.default)
    if (defaultStoreIndex > 0) {
      const elementWithDefault = { ...payload[defaultStoreIndex] }
      payload.splice(defaultStoreIndex, 1)
      payload.unshift(elementWithDefault)
    }
    state.favouriteStores = payload
  },

  [UPDATE_APPOINTMENTSBOOKING_LOADING]: function(state, payload) {
    state.appointmentsBooking = { ...payload }
  },
  [UPDATE_LOYALTYPRODUCTS_LOADING]: function(state, payload) {
    state.loyaltyProducts = payload
  },
  [UPDATE_LOYALTY_SELECTED_PRODUCT]: function(state, payload) {
    const selected = state.loyaltyProductSelected
    selected == payload
      ? (state.loyaltyProductSelected = null)
      : (state.loyaltyProductSelected = payload)
  },
  [UPDATE_LOAD_LOYALTY_SCRIPT]: function(state, payload) {
    state.loyaltyScriptLoaded = payload
  },
  [UPDATE_LOYALTY_STATUS]: function(state, payload) {
    state.profileDetails.objectPerson.isLoyaltyActive =
      payload?.status && payload?.status === 'ACTIVE' ? true : false
    state.profileDetails.objectPerson.loyaltyStatus = payload?.status || ''
    state.profileDetails.objectPerson.loyaltyCardNumber = payload?.cardNumber || ''
    state.profileDetails.objectPerson.loyaltyJwtToken = payload?.jwtToken || ''
    state.profileDetails.objectPerson.loyaltyNotMemberJwtToken = payload?.notMemberJwtToken || ''
    state.profileDetails.objectPerson.loyaltyTier = payload?.tier || ''
    setLoyaltyTrackingValues(state.profileDetails.objectPerson)
  },
  [UPDATE_CART_STATE]: function(state, payload) {
    state.cartState = payload
  },
}
