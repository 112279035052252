
import { loadSectionTranslations } from '../../i18n-setup'
export default {
  name: 'sgh-pay-over-time-popup',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    klarnaVisible() {
      return window.paymentInstallmentList.klarna.enabled
    },
    paypalVisible() {
      return window.paymentInstallmentList.paypal.enabled
    },
    afterpayVisible() {
      return window.paymentInstallmentList.afterpay.enabled
    },
    afterpayMessage() {
      return this.$t('details_afterpay', [
        window.paymentInstallmentList.afterpay.noOfInstallments,
        window.paymentInstallmentList.afterpay.minThreshold,
        window.paymentInstallmentList.afterpay.maxThreshold,
      ])
    },
    klarnaMessage() {
      return this.$t('details_klarna', [
        window.paymentInstallmentList.klarna.noOfInstallments,
        window.paymentInstallmentList.klarna.minThreshold,
      ])
    },
    paypalMessage() {
      return this.$t('details_paypal', [
        window.paymentInstallmentList.paypal.noOfInstallments,
        window.paymentInstallmentList.paypal.minThreshold,
        window.paymentInstallmentList.paypal.maxThreshold,
      ])
    },
    showAfterpaySeparator() {
      return this.afterpayVisible && (this.klarnaVisible || this.paypalVisible)
    },
    showPaypalSeparator() {
      return this.paypalVisible && this.klarnaVisible
    },
  },
  mounted: function() {
    loadSectionTranslations('minicart')
  },
  methods: {
    imagePath(name) {
      return `/sghstatichtml/assets/images/${name}.png`
    },
  },
}
